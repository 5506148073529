<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-8">
      <div class="card full-height bg-ligth">
        <div class="card-header">
          <h3 class="mb-0">Substancias

          </h3>
        </div>
        <div class="card-body">
          <div class="form-group row justify-content-center align-items-md-center">
            <div class="col-md-12">
              <input required type="text" class="form-control" v-model="filtro" placeholder="Buscar..." />
            </div>
          </div>
          <b-table :fields="['nome', 'acoes']" :items="lista_substancias" :per-page="perPage" :current-page="currentPage"
            id="substanias-table" class="table table-head-custom table-vertical-center table-head-bg table-borderless"
            show-empty empty-text="Ningún registro encontrado!">

            <template #head(acoes)><span></span></template>
            <template #cell(acoes)="{ item }">
              <div class="text-right w-100">

                <button v-show="lista_permissoes_filial.u_Sala" @click="atualizar(item)"
                  class="btn btn-icon btn-light btn-sm mx-1" v-b-tooltip.hover title="Editar registro">
                  <i class="far fa-edit text-primary"></i>
                </button>

                <button v-show="lista_permissoes_filial.lock_Sala" @click="confirm(item)"
                  class="btn btn-icon btn-light btn-sm mx-1" v-b-tooltip.hover title="Bloquear/Desbloquear registro">
                  <i class="fas fa-unlock-alt text-danger"></i>
                </button>
              </div>
            </template>
          </b-table>
          <b-pagination v-model="currentPage" :total-rows="lista_substancias.length" :per-page="perPage"
            aria-controls="substanias-table">
          </b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import { fireAlert } from "@/core/mixins/alertMessageMixin";
export default {
  mixins: { fireAlert },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      filtro:""
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Substancias" }]);
  },
  created() {
    this.listar_substancias();
  },
  watch: {
    filtro() {
      if (this.filtro.length >= 2) this.pesquisar_substancias();
    },
  },
  computed: {
    lista_substancias() {
      return this.$store.state.substancia.lista_substancias;
    },
    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },
    mensagem_alert() {
      return this.$store.state.substancia.mensagem_alert;
    },
  },
  methods: {
    async listar_substancias() {
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      await this.$store.dispatch("substancia/listar_substancias").finally(() => {
        this.$store.dispatch("configEmpresa/MudarPreloader", false);
        this.$store.dispatch("configEmpresa/atualizar", "");
      });
    },
    atualizar(value) {
      this.$router.push({ name: "createSubstancia" });
      this.$store.dispatch("configEmpresa/atualizar", value);
    },
    async confirm(value) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação bloqueara esta Sala no sistema?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            this.bloquear(value);
          }
        },
      });
    },
    async bloquear(value) {
      await this.$store.dispatch("substancia/bloquear_substancia", value);
      this.fireAlert({
        ...this.mensagem_alert, routeName: 'sala'
      });
    },
    async pesquisar_substancias() {
      await this.$store
        .dispatch("substancia/pesquisar_substancias", this.filtro)
        .finally(() => {
          this.$store.dispatch("configEmpresa/atualizar", "");
        });
    },

  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>